import { Controller } from "@hotwired/stimulus";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

import axios from "axios";

axios.defaults.headers.common = {
  // "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
};

export default class extends Controller {
  static targets = [ "code", "apply" ];

  availableVisitTypes = [];

  initialize() {
    window.$(this.applyTarget).on("click", this.validatePromotionalCode.bind(this));
    window.$(this.codeTarget).on("change", this.updateWarning.bind(this));
  }

  updateWarning(ev) {
    const $code = window.$(this.codeTarget);

    if ($code.val().trim() === "") {
      $code.parents("form").unbind("submit.promotional_code")
    } else {
      $code.parents("form").on("submit.promotional_code", function(e) {
        e.preventDefault();

        Swal(I18n.t("booking.details.promotional_codes.forgotten_to_apply"), "", "warning");

        return false;
      });
    }
  }

  validatePromotionalCode(ev) {
    ev.preventDefault();

    const code = window.$(this.codeTarget).val();

    if (code === "") {
      Swal(I18n.t("booking.details.promotional_codes.empty_promotional_code_error"), "", "warning");
      return;
    }

    axios.post(
      "/booking/apply_promotional_code",
      {
        promotional_code: code
      }
    ).
    then((res) => {
      // Unbind promotional code warning
      const $code = window.$(this.codeTarget);
      $code.parents("form").unbind("submit.promotional_code");

      const $summary = window.$("#summary-column");
      $summary.html($(res.data));

      const nextButtonText = $summary.find("[data-next-button-text]").data("next-button-text");
      window.$("#next-button").html(nextButtonText);

      Swal(I18n.t("booking.details.promotional_codes.success"), "", "success");
    }).catch(function (error) {
      Swal(I18n.t("booking.details.promotional_codes.error"), "", "error");
    });
  }
}
