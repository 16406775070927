import React from "react"
import PropTypes from "prop-types";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../admin/shared/calendar.css"

import moment from "moment/moment";
import MomentLocaleUtils from "react-day-picker/moment";

import "moment/locale/ca"
import "moment/locale/es"

import Axios from "axios";

import BlockUi from '@availity/block-ui';
import Loader from '../shared/loader';

Axios.defaults.headers.common = {
  // "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
};

class BookingSchedules extends React.Component {
  cancelTokenSource = null;

  constructor(props) {
    super(props);

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleScheduleClick = this.handleScheduleClick.bind(this);

    this.state = {
      busyDays: [],
      currentDay: null,
      daysWithSlots: [],
      firstDayToShow: moment().add(1,'days').toDate(),
      loadingCalendar: true,
      loadingSchedules: false,
      schedules: [],
      currentSchedule: props.initialSchedule,
      initialDay: props.initialDay ? moment(props.initialDay).toDate() : null,
    };
  }

  componentDidMount = () => {
    this.cancelTokenSource = Axios.CancelToken.source();

    Axios.get(`/api/calendar/available_days`, {
      cancelToken: this.cancelTokenSource.token,
      headers: {
        Accept: "application/json"
      },
      withCredentials: true,
      params: {
        visit_type_id: this.props.visit_type_id,
        adults: this.props.adults,
        children: this.props.children,
        kids: this.props.kids
      }
    })
    .then((response) => {
      let busyDays = [];
      let daysWithSlots = [];

      window.$.each(response.data.scheduled_days, (day, dayStats) => {
        if (dayStats.status === "isEmpty" || dayStats.status === "hasSlots") {
          daysWithSlots.push(day);
        } else {
          busyDays.push(day);
        }
      });

      this.setState({
        busyDays: busyDays,
        daysWithSlots: daysWithSlots,
        firstDayToShow: this.state.initialDay || new Date(response.data.first_available_day),
        loadingCalendar: false,
      });

      if (this.state.initialDay) {
        const { currentSchedule } = this.state;
        this.handleDayClick(this.state.initialDay, { currentSchedule });
      }
    })
    .catch((error) => {
      if (Axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        // callback (error, { options: [], complete: false });
      }
    });
  };

  componentWillUnmount() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
  }

  handleDayClick(day, { currentSchedule = null }) {
    // const { daysWithSlots } = this.state;

    if (! this.selectableDay(day)) {
      return;
    }

    this.setState({ loadingSchedules: true });

    const day_string = moment(day).format("YYYYMMDD");

    Axios.get(`/api/calendar/available_schedules`, {
      headers: {
        Accept: "application/json"
      },
      withCredentials: true,
      params: {
        day: day_string,
        visit_type_id: this.props.visit_type_id,
        adults: this.props.adults,
        children: this.props.children,
        kids: this.props.kids
      }
    }).
    then((res) => {
      this.setState({
        currentDay: day,
        currentSchedule: currentSchedule,
        loadingSchedules: false,
        schedules: res.data
      });

      this.setTourScheduleId(currentSchedule || "");
    });

    // this.setState({
    //   currentDay: selected ? day : undefined,
    // });
  };

  handleScheduleClick(ev) {
    const scheduleID = ev.target.dataset.scheduleid;

    this.setState({
      currentSchedule: scheduleID
    });

    this.setTourScheduleId(scheduleID);
  }

  setTourScheduleId(val) {
    window.$("input#booking_calendar_tour_schedule_id").val(val);

    val ? this.enableSubmit() : this.disableSubmit();
  }

  disableSubmit() {
    const $submitButton = window.$("form.new_booking, form.edit_booking").find("button[type=submit]");
    $submitButton.addClass("disabled:opacity-75");
    $submitButton.prop('disabled', true);
  }

  enableSubmit() {
    const $submitButton = window.$("form.new_booking, form.edit_booking").find("button[type=submit]");
    $submitButton.removeClass("disabled:opacity-75");
    $submitButton.prop('disabled', false);
  }

  selectableDay = (date) => {
    return this.state.daysWithSlots.includes(moment(date).format('YYYY-MM-DD'));
  }

  render() {
    const modifiers = {
      busy: (date) => this.state.busyDays.includes(moment(date).format('YYYY-MM-DD')),
      current: this.state.currentDay,
    };

    const { currentSchedule } = this.state;

    return (
      <div style={{minHeight: "430px"}}>
        <div className="container flex justify-around space-x-6">
          <div className="w-full sm:w-7/12">
            <h3 className="text-md mb-4 text-fageda-blue-200 font-bold text-center">{I18n.translate("booking.schedule.what_day")}</h3>
            <BlockUi tag="div" className="text-center" blocking={this.state.loadingCalendar} loader={<Loader />}>
              <DayPicker
                className="w-full"
                numberOfMonths={this.props.numberOfMonths || 2}
                month={this.state.firstDayToShow}
                pagedNavigation={true}
                disabledDays={[
                  { before: moment().add(1,'days').toDate() }
                ]}
                firstDayOfWeek={1}
                modifiers={modifiers}
                locale={window.Fageda.current_locale}
                localeUtils={MomentLocaleUtils}
                // navbarElement={<Navbar />}
                // renderDay={this.renderDay}
                selectedDays={this.selectableDay}
                onDayClick={this.handleDayClick}
              />
            </BlockUi>
            <div className="calendar-legend">
              <div className="legend-item">
                <span className="legend-color legend-color--available" /> {I18n.translate("booking.schedule.day_available")}
              </div>
              <div className="legend-item">
                <span className="legend-color legend-color--busy" /> {I18n.translate("booking.schedule.day_full")}
              </div>
              <div className="legend-item">
                <span className="legend-color legend-color--day" /> {I18n.translate("booking.schedule.day_not_available")}
              </div>
              <div className="legend-item">
                <span className="legend-color legend-color--current" /> {I18n.translate("booking.schedule.selected_day")}
              </div>
            </div>
          </div>

          <div className="w-full sm:w-5/12">
            <h3 className="text-md mb-4 text-fageda-blue-200 font-bold text-center">{I18n.translate("booking.schedule.what_hour")}</h3>
            <BlockUi tag="div" className="text-center" blocking={this.state.loadingSchedules} renderChildren={false} loader={<Loader />}>
              <table className="w-full">
                <thead className="leading-10">
                <tr className="border-t border-b text-black border-gray-200">
                  <th scope="col">{I18n.translate("booking.schedule.hour")}</th>
                  <th scope="col">{I18n.translate("booking.schedule.duration")}</th>
                  <th scope="col">{I18n.translate("booking.schedule.language")}</th>
                  <th scope="col" />
                </tr>
                </thead>
                <tbody>
                {!this.state.currentDay && <tr><td className="pt-2 font-thin text-center" colSpan="4">{I18n.translate("booking.schedule.please_select_a_day")}</td></tr>}
                {this.state.schedules.map((schedule, idx) =>
                  <tr key={idx} className="border-t border-b border-gray-200">
                    <td className="text-center py-2">{schedule.hour}</td>
                    <td className="text-center py-2">{schedule.duration} min.</td>
                    <td className="text-center py-2">{schedule.language}</td>
                    <td className="text-right py-2" width="1%">
                      { currentSchedule != schedule.id &&
                        <button className="bg-gray-200 px-3 py-1 rounded-full" size="sm" data-scheduleid={schedule.id} onClick={this.handleScheduleClick}>
                          {I18n.translate("booking.schedule.select")}
                        </button>
                      }
                      { currentSchedule == schedule.id &&
                        <button className="bg-fageda-blue-200 w-full px-3 py-1 rounded-full" size="sm" data-scheduleid={schedule.id} onClick={this.handleScheduleClick}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto " fill="white" viewBox="0 0 16 16">
                          <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z" fill="white"/>
                          </svg>
                        </button>
                      }
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </BlockUi>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingSchedules
