import { Controller } from "@hotwired/stimulus";
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends Controller {
  connect() {
    const Component = window.Fageda.ReactComponents[this.data.get('component')];

    let props = this.data.get('props') || {};
    if (typeof props === 'string') {
      props = JSON.parse(props);
    }

    ReactDOM.render(
      <Component { ...props } />,
      this.element
    );

    document.addEventListener('turbo:before-cache', this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.unmountComponent);
  }

  unmountComponent = () => {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}
