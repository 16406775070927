import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "visitType", "adults", "children", "kids" ];

  availableVisitTypes = [];

  initialize() {
    this.visitTypeTargets.forEach((el, _index) => {
      $(el).on('change', this.updateView.bind(this));
    });

    this.availableVisitTypes = JSON.parse(this.data.get("types")).data;

    this.updateView();
  }

  updateView() {
    this.visitTypeTargets.forEach((el, _index) => {
      const $el = window.$(el);
      const checked = $el.is(":checked");

      $el.parent().siblings('.card-body').toggleClass("hidden", !checked);

      if (checked) {
        const visit_type = this.availableVisitTypes.find((visit_type) => {
          return visit_type.id.toString() === $el.val().toString();
        });

        let $adultsWrapper = window.$(this.adultsTarget);
        let $childrenWrapper = window.$(this.childrenTarget);
        let $kidsWrapper = window.$(this.kidsTarget);

        // Update input labels
        $adultsWrapper.find('label').text(visit_type.attributes.adultsLabel);
        $childrenWrapper.find('label').text(visit_type.attributes.childrenLabel);
        $kidsWrapper.find('label').text(visit_type.attributes.kidsLabel);

        // Hide unnecessary inputs
        $childrenWrapper.toggleClass("hidden", visit_type.attributes.childrenHidden);
        $kidsWrapper.toggleClass("hidden", visit_type.attributes.kidsHidden);

        // Reset inputs (don't disable it, as we need to update the data in session with empty values)
        if (visit_type.attributes.childrenHidden) {
          $childrenWrapper.find('input').val("");
        }

        if(visit_type.attributes.kidsHidden) {
          $kidsWrapper.find('input').val("");
        }
      }
    })
  }
}
