import { Controller } from "@hotwired/stimulus";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

export default class extends Controller {
  static targets = [ "content" ];

  open(ev) {
    ev.preventDefault();
    const $content = window.$(this.contentTarget);

    new Swal({
      html: $content.html(),
      type: "",
      confirmButtonColor: "#1d3e97",
      width: '75%',
      customClass: "swal2-text-justify",
      customContainerClass: "swal2-text-justify",
      animation: false
    });
  }
}
