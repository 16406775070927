import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ['item']

  toggle(event) {
    this.itemTargets.forEach(item => {
      item.classList.add('hidden')
    })

    let { targetId } = event.params

    if (targetId.startsWith(":"))
      targetId = event.currentTarget[targetId.replace(/^:/, '')]

    const target = this.element.querySelector(`#reveal-${targetId}`)

    if (target)
      target.classList.remove('hidden')
  }
}
